import React, { useEffect, useState, useRef } from 'react'
import { Table, Card, Flex, Input, Tooltip, Button, Spin, Row, Col, Radio} from 'antd';
import constants from '../../Constants/constants'
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { useNavigate } from 'react-router-dom';
import { performSearch, CustomSortIcon, HighlightedText, getNextAndPreviousButtons, showTotalItems, handleTableChange, handleRowSelect } from '../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi } from '../../Services/Api';
import { useMediaQuery } from 'react-responsive';
import Print from '../Component/PrintComponent/Print';

const FacultyListing = () => {
	const pageTitle = constants.StaffListing
	const breadcrumbItems = [
		{ label: constants.StaffListing, active: true },]
	const [facultyList, setFacultyList] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [isdisabled, setIsDisabled] = useState(true)
	const [searchText, setSearchText] = useState('')
	const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState([])
	const [paginationEnabled, setPaginationEnabled] = useState(true);
	const [loading, setLoading] = useState(false)
	const [isSelectedZero,setIsSelectedZero] = useState(true)
	const [sortedTableData, setSortedTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableSorters, setTableSorters] = useState([]);
	const isMobileDesktop = useMediaQuery({
		query: '(min-width: 576px)'
	})
	const [status,setStatus] = useState(null)
	const navigate = useNavigate()
	const selectionCheck = () =>{
		if (rowKeys.length !== 1) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
		if(rowKeys.length === 0){
			setIsSelectedZero(true)
		}else{
			setIsSelectedZero(false)
		}
	}

	const getFacultyList = (facultyType) => {
		const modifyData = (data) => {
      return data?.map((item) => {
        return {
          ...item,
          faculty_university_email: item.faculty_university_email || '',
					is_active_text: item?.is_active === '0' ? constants.NotLoggedIn : 
					item?.is_active === '1' ? constants.Active :
					item?.is_active === '2' ? constants.InActive :
					constants.Archived,
        }
      })
    }

		setLoading(true)
		let url = facultyType?`/get-faculty-list?is_active=${facultyType}` :`/get-faculty-list`
		fetchStaffApi(url, 'GET')
			.then((res) => {
				if (res?.code === '200') {
					let data = res?.data?.faculties?.length > 0 ? modifyData(res.data.faculties) : []
					setFacultyList(data);
					setFilteredData(data)
					onTableChange(currentPage, null, tableSorters, data, false)
				} else {
					setFacultyList([]);
					setSortedTableData([]);
				}
				setLoading(false)
			})
			.catch((error) => {
			});
	}
	const onLoadList = (e) =>{
		let facultyType=e.target.value?e.target.value:e;
		setStatus(e.target.value?e.target.value:e)
		if(facultyType==='1' || facultyType==='3' || facultyType==='2'){
			getFacultyList(facultyType)
		}else{
			getFacultyList()
		}
			setSelectedRecord([])
			setRowKeys([])
			setRowData([])
	}

	useEffect(()=>{
		selectionCheck()
	},[onLoadList])
	useEffect(() => {
		getFacultyList('1')
	}, [])
	const handleBeforePrint = () => {
		setPaginationEnabled(false);
	};
	const handleAfterPrint = () => {
		setPaginationEnabled(true);
	};
	const tableRef = useRef();
	const onSearch = (event) => {
		const fieldNames = ['faculty', 'faculty_university_email', 'user_role','faculty_mobile_number']
		setSearchText(event.target.value);
		let searchedValue = performSearch(event.target.value, filteredData, fieldNames);
		setFacultyList(searchedValue);
		setSortedTableData(searchedValue);
	};
	const formatPhoneNumber = (phoneNumber) => {
		// Check if the phone number is already formatted
		const regex = /^\d{3}-\d{3}-\d{4}$/;
		if (!regex.test(phoneNumber)) {
			// If not, format the phone number
			return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
		}
		return phoneNumber;
	};

	const columns = [
		{
			title: ` ${constants.Name}`,
			columnName: constants.Name,
			dataIndex: 'faculty',
			sorter: (a, b) => a.faculty?.localeCompare(b.faculty),
			sortIcon: ({ sortOrder }) => paginationEnabled ? <CustomSortIcon sortOrder={sortOrder} /> : '',
			render: (faculty) => <HighlightedText text={faculty} highlight={searchText} />,
			width: '20%',
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: `${constants.CellPhone}`,
			columnName: constants.CellPhone,
			dataIndex: constants.FacultyMobileNumber,
			sorter: paginationEnabled ? null : (a, b) => a.faculty_mobile_number?.localeCompare(b.faculty_mobile_number),
			sortIcon: ({ sortOrder }) => paginationEnabled ? <CustomSortIcon sortOrder={sortOrder} /> : '',
			render: (mobile_number) => <HighlightedText text={formatPhoneNumber(mobile_number)} highlight={searchText} />,
			width: '15%',
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.MuEmail,
			columnName: constants.MuEmail,
			dataIndex: 'faculty_university_email',
			sorter: paginationEnabled ? (a, b) => a.faculty_university_email?.localeCompare(b.faculty_university_email) : false,
			sortIcon: ({ sortOrder }) => paginationEnabled ? <CustomSortIcon sortOrder={sortOrder} /> : '',
			render: (email) => <HighlightedText text={email?email:'-'} highlight={searchText} />,
			width: '25%',
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.Position,
			columnName: constants.Position,
			dataIndex: constants.UserRole,
			sorter: paginationEnabled ? (a, b) => a.user_role?.localeCompare(b.user_role) : false,
			sortIcon: ({ sortOrder }) => paginationEnabled ? <CustomSortIcon sortOrder={sortOrder} /> : '',
			render: (user_role) => <HighlightedText text={user_role} highlight={searchText} />,
			width: '18%',
			showSorterTooltip: { show: true, placement: 'bottom' },
		},
		{
			title: constants.Status,
			columnName: constants.Status,
			dataIndex: 'is_active_text',
			render: (_, { is_active_text }) => <Tooltip placement="top" title={is_active_text}>{is_active_text}</Tooltip>,
			width: '10%'
		},
	];

	const rowSelectionRadio = {
		type: 'checkbox',
		columnWidth: '5%',
		selectedRowKeys: rowKeys,
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? facultyList:[]
			onSelectChange(
			data?.map((item) => item.id),
			data,
			null,
			null,
			true
		)},
		onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			facultyList?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
	};

	const getRowClassName = (record) => {
		return record.is_advisor === 1 ? 'advisor-row' : '';
	};

	const navigateToModule = (navigateLink,passState) =>{
		navigate(navigateLink,{ state: passState})
	}

	function onTableChange(current, filters, sorter, currentDataSource, fromTableChange) {
		let result = handleTableChange(current, filters, sorter, currentDataSource, fromTableChange)
		setCurrentPage(result?.current)
		setTableSorters(result?.sorter)
		setSortedTableData(result?.data)
	}

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null, selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
		selectionCheck()
		if(!selectAll) 
			setSelectedRecord(record ? record?.id : selectedRowData[0]?.id)
  };

	return (
		<div>
			<div>
				<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
				<Card className="container-border-custom">
				<Flex justify='space-between'>
					<Radio.Group onChange={(e) => onLoadList(e)} defaultValue={'1'}>
						<Radio value={"1"}>{constants.ShowActive}</Radio>
						<Radio value={"2"}>{constants.ShowInactive}</Radio>
						<Radio value={"3"}>{constants.ShowDeactivated}</Radio>
						<Radio value={"0"}>{constants.ShowAll}</Radio>
					</Radio.Group>
				</Flex>
					<Row className='mt-2'>
						<Col xs={24} sm={12} xl={18} lg={12} md={12} className='mt-1' >
							<Flex gap={'small'} align='flex-start' wrap='wrap' horizontal>
								<Button className="text-capitalize btn-info" disabled={isdisabled} onClick={()=>navigateToModule('/staff-edit',{ editrecord: selectedRecord, isProfile: false })}>
									{constants.Edit}
								</Button>
								<Print
									columns={columns}
									currentPage={currentPage}
									data={sortedTableData}
									disabled={sortedTableData?.length === 0}
									headerTitle={constants.StaffListing}
									type='json'
									key='print' //OPTIONAL
							/>
								<Button className=" text-capitalize btn-info" disabled={isSelectedZero} 
								onClick={()=>navigateToModule('/send-email',{ emailState: rowData })}
								> {constants.Email}</Button>

								<Button className=" text-capitalize btn-info" disabled={isSelectedZero} 
								onClick={()=>navigateToModule('/notification-add',{ notificationState: rowData })}
								>{constants.Notification}</Button>
								
							</Flex>
						</Col>
						<Col xs={24} xl={6} lg={12} md={12} sm={12} className='mt-1'>
							<Flex align="flex-end" vertical >
								<Input placeholder='Search' className={isMobileDesktop ? "w-48" : 'w-100'}
									onChange={onSearch}
								/>
							</Flex>
						</Col>
					</Row>
					<div ref={tableRef}>
						<Spin tip={constants.PleaseWait} spinning={loading}>
							<Table 
								rowSelection={rowSelectionRadio}
								columns={columns} 
								dataSource={facultyList} 
								bordered 
								size="small" 
								rowKey={(record) => record.id}
								className="records-table data_table mt-2"  
								pagination={paginationEnabled ? {
									current: currentPage,
									pageSize: constants.TablePageSize,
									showTotal: (total, range) => showTotalItems(total, range, facultyList),
									itemRender: getNextAndPreviousButtons,
								} : false} 
								searchableProps={{ fuzzySearch: true }}
								scroll={{ y: 'calc(100vh - 325px)', x: 1000 }}
								rowClassName={(record)=>getRowClassName(record)}
								rowHoverable={false}
								onChange={({ current }, filters, sorter, { currentDataSource }) => onTableChange(current, filters, sorter, currentDataSource, true)}
								onRow={(record, rowIndex) => {
									return {
										onClick: () => onSelectChange(null, null, record, rowIndex),
										onDoubleClick: () => navigateToModule('/staff-edit', { editrecord: record?.id, isProfile: false }),
									}
								}}
							/>
						</Spin>
					</div>
				</Card>
			</div>
		</div>
	)
}
export default FacultyListing
