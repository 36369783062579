import React, { useState, useEffect } from 'react'
import BreadcrumbHeader from '../Component/BreadcrumbHeader'
import constants from '../../Constants/constants'
import { Avatar, Card, Col, Row, message, Alert, Flex, Modal, Table } from 'antd'
import { fetchApi, fetchStaffApi } from '../../Services/Api'
import { COOKIE, getCookie } from '../../Services/Cookie'
import { UserOutlined, WarningFilled } from '@ant-design/icons'
import { faEnvelope, faPhone, faMobile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateAdviseeMenteeListColumn, removeExtraSpaces, surveyRedirectRoute } from '../../GlobalFunctions/GlobalFunctions';
import { useOutletContext, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { encodeURI } from "js-base64";

const activeCourses = [
  {
    course_id: '1',
    course_code: 'SLP 555',
    course_name: '555 Course',
    course_type: 'SLP Undergraduate',
    section: '1',
    credits: '4',
    faculty_assigned: 'Harry Maguire',
  },
  {
    course_id: '2',
    course_code: 'SLP 211',
    course_name: '211 Course',
    course_type: 'SLP Graduate',
    section: '5',
    credits: '2',
    faculty_assigned: 'Kevin Hart',
  },
]

const StudentDashboard = () => {
  const breadcrumbItems = [
    { label: 'Student Dashboard', href: '/', active: true },]

  const [dataSource, setDataSource] = useState(null);
  const [redirectNotificationPopupOpen, setRedirectNotificationPopupOpen] = useState(false);
  const [notificationRedirectUrl, setNotificationRedirectUrl] = useState('');
	const [data, updateNotificationData] = useOutletContext();
  const [listColumn, setListColumn] = useState([]);
  const [activeCourseList, setActiveCourseList] = useState([]);
  const isActive = getCookie(COOKIE.IsActive)
  const isMentor = getCookie('isMentor') === '1'
	const userId = getCookie('userId')
  const navigate = useNavigate()

  useEffect(() => {
    getFacultyCourses();
  }, []);

  const getFacultyCourses = () => {
    fetchStaffApi(`/student-dashboard?id=${userId}`, 'GET', null, 'student').then((res) => {
      if (res?.code === "200") { 
        setDataSource(res?.data[0])
        checkURLRedirect()
      }
      if(isMentor) fetchMenteeList();
      else getActiveCourses();
    });
  }

  //get list of advisees for advisor
	const fetchMenteeList = async () => {
		let payload = {
			year: 0,
			type: 0,
			semester: 0,
			mentor_id: userId,
		}
		fetchApi('/get-mentor-students', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				generateAdviseeMenteeListColumn(
          res?.data?.length > 0 ? res.data : [], 
          'name',
          setListColumn
        )
        getActiveCourses()
				return
			}
      getActiveCourses()
			generateAdviseeMenteeListColumn([], '', setListColumn)
		});
	};

  //get list of active courses
  const getActiveCourses = async () => {
    let payload = {
			student_id: userId,
		}
		fetchApi('/view-course-details', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setActiveCourseList(res?.data?.length > 0 ? res.data : [])
        return
			}
			setActiveCourseList([])
		}).catch(() => {
      setActiveCourseList([])
    });
  }

  const checkURLRedirect = () => {
    try {
      const URL = window.location.search
      if(!URL) return
      const urlParams = new URLSearchParams(URL);
      let survey_id = urlParams.get('survey_id')
      let survey_type = urlParams.get('survey_type')

      if(survey_id && survey_type) {
        let obj = {
          survey_id: survey_id,
          survey_type: survey_type,
        }
        setRedirectNotificationPopupOpen(true)
        setNotificationRedirectUrl(surveyRedirectRoute(obj, true));
      } else message.error(constants.ErrorMessage)
    } catch (error) {
      message.error(constants.ErrorMessage)
    }
  }

  const renderMessage = (item) => (
		<Row>
			<Col span={12}>
				{item.notification_url ? (
					<span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
				) : (
					<span className="noti-mb-0" dangerouslySetInnerHTML={{ __html: removeExtraSpaces(item.notification_text) }}></span>
				)}
			</Col>
			<Col span={12}>
				<Flex justify="end">
					{dayjs(item.dt_created).format("MMM DD, YYYY [at] h:mm A")}
				</Flex>
			</Col>
		</Row>
	);

  const activeCoursesColumns = [
		{
			title: 'Course No. / Area',
			dataIndex: 'course_code',
			key: 'course_code',
		},
    {
			title: 'Course Name',
			dataIndex: 'course_name',
			key: 'course_name',
		},
    {
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Section',
			dataIndex: 'section_number',
			key: 'section_number',
      render: (_, record) => <>{record?.section_number || '-'}</>
		},
    {
			title: 'Credits',
			dataIndex: 'course_credit',
			key: 'course_credit',
		},
		{
			title: 'Faculty Assigned',
			dataIndex: 'faculty_name',
			key: 'faculty_name',
      render: (_, record) => <>{record?.faculty_name || '-'}</>
		},
	]
  
  return (
    <div>
      <BreadcrumbHeader pageTitle="Dashboard" breadcrumbItems={breadcrumbItems} />
      {
        <>
          {data
						?.filter((item) => item.priority === "1")
            .slice(0, 2)
						.map((item) => (
							<Alert
								className="mb-3"
								key={item.id}
								showIcon
								icon={<WarningFilled />}
								type="error"
								message={renderMessage(item)}
							/>
						))}
        </>
      }
      <Card className="container-border-custom">
        {isActive === "0" ?
          <div className='student-dashboard-welcome-container'>
            <h4 className='welcome-text'>{constants.welcomeText}</h4>
            <p className='welcome-text-body'>{constants.dashboardText}</p>
          </div> : null}

        {(dataSource?.mentor_id || dataSource?.faculty_id) &&
        <Row gutter={[8, 8]}>
          {dataSource?.mentor_id && (
            <Col xs={24} lg={12}>
              <Card className='dashboard-card-head' title="Mentor" bordered={false}>
                <Row gutter={15}>
                  <Col>
                    {dataSource.student_image == null ? <Avatar shape="square" size={100} icon={<UserOutlined />} alt='avatar' /> :
                      <Avatar shape="square" size={100} src={<img src={dataSource?.student_image} alt='avatar' />} alt='avatar' />}
                  </Col>
                  <Col>
                    <ul className='no-decoration-list'>
                      <li>{dataSource?.mentor_name}</li>
                      {dataSource?.mentor_university_email ? (
                        <li>
                          <FontAwesomeIcon className='font-align' icon={faEnvelope} />
                          <a href={`mailto:${dataSource.mentor_university_email}`}>{dataSource.mentor_university_email}</a>
                        </li>
                      ) : ""}

                      {dataSource?.student_mentor_mobile_number ? (
                        <li>
                          <FontAwesomeIcon className='font-align' icon={faPhone} />
                          <a href={`tel:${dataSource.student_mentor_mobile_number}`}>{dataSource.student_mentor_mobile_number}</a>
                        </li>
                      ) : ""}
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>)}
          {dataSource?.faculty_id && (
            <Col xs={24} lg={12}>
              <Card className='dashboard-card-head' title="Advisor" bordered={false}>
                <Row gutter={[15, 15]}>
                  <Col>
                    {dataSource.faculty_image == null ? <Avatar shape="square" size={100} icon={<UserOutlined />} alt='avatar' /> :
                      <Avatar shape="square" size={100} src={<img src={dataSource?.faculty_image} alt='avatar' />} alt='avatar' />}
                  </Col>
                  <Col>
                    <ul className='no-decoration-list'>
                      <li>{dataSource?.faculty_advisor_name}</li>
                      {dataSource?.advisor_university_email ?
                        <li>
                          <FontAwesomeIcon className='font-align' icon={faEnvelope} />
                          <a href={`mailto:${dataSource.advisor_university_email}`}>{dataSource?.advisor_university_email}</a>
                        </li>
                        : ""}
                      {dataSource?.faculty_advisor_office_number ?
                        <li>
                          <FontAwesomeIcon className='font-align' icon={faPhone} />
                          <a href={`tel:${dataSource.faculty_advisor_office_number}`}>{dataSource?.faculty_advisor_office_number}</a>
                        </li>
                        : ""}
                      {dataSource?.is_visible_mobile_number === "1" && (
                        <li>
                          <FontAwesomeIcon className='font-align' icon={faMobile} />
                          <a href={`tel:${dataSource.faculty_advisor_mobile_number}`}>{dataSource?.faculty_advisor_mobile_number}</a>
                        </li>
                      )}
                    </ul>
                  </Col>
                </Row>
              </Card>
            </Col>)}
        </Row>}
      </Card>
      {
        isMentor && (
          <Card className="mb-3 container-border-custom dashboard-card">
            {listColumn && 
            <Card
              title='My Mentee(s)'
              className='dashboard-card-head'
            >
              <Row gutter={[16, 16]}>
                {listColumn}
              </Row>
            </Card>}
          </Card>
        )
      }    
      <Card 
        title='My Active Courses for this Semester.'
        className='container-border-custom dashboard-card'>
          <Table
            className="records-table data_table"
            size="small"
            columns={activeCoursesColumns}
            dataSource={activeCourseList}
          />
      </Card>
      <Modal
				centered
        maskClosable={false}
				title={constants.PleaseNote}
				open={redirectNotificationPopupOpen}
				onOk={() => navigate(notificationRedirectUrl)}
				onCancel={() => setRedirectNotificationPopupOpen(false)}
				footer={(_, { OkBtn, CancelBtn }) => (
					<>
						<CancelBtn />
						<OkBtn />
					</>
				)}
			>
				<p>{`You will be redirected to survey, do you want to proceed?`}</p>
			</Modal>
    </div>

  )
}
export default StudentDashboard






