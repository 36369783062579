import React, { useEffect, useState } from 'react';
import { Card, Button, Tabs, Space, Tag } from "antd";
import constants from '../../Constants/constants';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { useLocation, useNavigate } from "react-router-dom";
import EditStudent from './EditStudent';
import Checklist from './Checklist';
import PlanOfStudy from './PlanOfStudy';
import SemesterCourses from './SemesterCourses';
import Courses from './StudentCourses/Courses';
import History from './History';
import { COOKIE, getCookie } from '../../Services/Cookie';
import ArchivedStudentDetails from './ArchivedStudent/ArchivedStudentDetails';
import StudentRelatedNotes from '../Advising/StudentRelatedNotes';

const { TabPane } = Tabs;

const StudentTabs = () => {
  const location = useLocation()?.state;
  const [activekey, setActivekey] = useState(location?.tabkey);
  const [selectedTabTitle, setSelectedTabTitle] = useState(location?.tab_title);
  const facultyRoleId = getCookie(COOKIE.Role);
  const isAdminOrSysAdmin = facultyRoleId === "1" || facultyRoleId === "3";
  const facultyIdFromEdit = getCookie(COOKIE.FacultyIdFromEdit);
  const facultyLoggedInId = getCookie(COOKIE.UserId);
  const isFacultyIdMatch = isAdminOrSysAdmin || (facultyIdFromEdit === facultyLoggedInId) //Checking if Logged in faculty is the same as the advisor for that student in Edit student page.
  let isArchived = location?.isArchived;
  let reloadTab = location?.reloadTab;
  const breadcrumbItems = [
    { label: location?.from, href: location?.from_route },
    { label: <span>{isArchived && constants.Archived}{selectedTabTitle}</span>, active: true },
  ]
  const breadcrumbItemsArchived = [
    { label: location?.from, href: location?.from_route },
    { label: <span>{constants.Archived}</span>, active: true },
    { label: <span>{selectedTabTitle}</span>, active: true },
  ]
  useEffect(() => {
    let tabKey = sessionStorage.getItem('student_active_key') || location?.tabkey
    if (tabKey) setActivekey(tabKey);
    return () => {
      sessionStorage.removeItem('student_active_key');
    }
  }, [reloadTab]);

  const handleTabSelect = (key, event) => {
    setActivekey(key);
    const selectedTab = event.target.innerText;
    setSelectedTabTitle(selectedTab);
    sessionStorage.setItem('student_active_key', key);
  };

  return (
    <div>
      {isArchived ?
        <BreadcrumbHeader
          pageTitle={
            <div>
              <Space>{location?.student_name}<Tag bordered={false} color='#337ab7'>{constants.Archived}</Tag></Space>
            </div>
          }
          breadcrumbItems={breadcrumbItemsArchived}
        /> :
        <BreadcrumbHeader
          pageTitle={<span>{selectedTabTitle} - {location?.student_name}</span>}
          breadcrumbItems={breadcrumbItems}
        />
      }
      <Card className="container-border-custom">
        <Tabs
          type="card"
          className="card-tabs"
          onTabClick={handleTabSelect}
          activeKey={activekey}
        >
          <TabPane key="StudentDetails" className='pb-2' tab={<span className=" text-capitalize fs-7">{constants.StudentDetails}</span>}>
            {activekey === "StudentDetails" ? isArchived ? <ArchivedStudentDetails /> : <EditStudent /> : null}
          </TabPane>
          {
            isFacultyIdMatch && <>
              <TabPane key="advising-notes" className='py-2' tab={<span className=" text-capitalize fs-7">{constants.AdvisingNotes}</span>}>
                {activekey === "advising-notes" && <StudentRelatedNotes student_id={location?.student_id} student_name={location?.student_name} />}
              </TabPane>
              {
                !isArchived &&
                <TabPane key="courses" className='py-2' tab={<span className=" text-capitalize fs-7">{constants.Courses}</span>}>
                  {activekey === "courses" && <Courses student_id={location?.student_id} student_name={location?.student_name} sem_id={location?.sem_id} />}
                </TabPane>
              }
              <TabPane key="semester-courses" className='py-2' tab={<span className=" text-capitalize fs-7">{constants.SemesterCourses}</span>}>
                {activekey === "semester-courses" && <SemesterCourses student_id={location?.student_id} sem_id={location?.sem_id} year_in_program={location?.year_in_program} student_advisor_id={getCookie(COOKIE.UserId)} student_name={location?.student_name}locationData={location} />}
              </TabPane>
              <TabPane key="PlanOfStudy" className='py-2' tab={<span className=" text-capitalize fs-7">{constants.PlanOfStudyAdvisement}</span>}>
                {activekey === "PlanOfStudy" && <PlanOfStudy student_id={location?.student_id} />}
              </TabPane>
              <TabPane key="Checklist" className='py-2' tab={<span className=" text-capitalize fs-7">{constants.Checklist}</span>}>
                {activekey === "Checklist" && <Checklist student_id={location?.student_id} />}
              </TabPane>
              <TabPane key="History" className='py-2' tab={<span className=" text-capitalize fs-7">{isArchived ? constants.AdvisementDocumentation : constants.History}</span>}>
                {activekey === "History" && <History student_id={location?.student_id} student_name={location?.student_name} />}
              </TabPane></>
          }
        </Tabs>
      </Card>
    </div>
  )
}

export default StudentTabs